<template>
  <div class="index_container chatRoomList">
    <div class="chatRoomList_main">
      <ListScroll :url="$urls.user.executorMeetList">
        <template v-slot:listItem="{ cItems }">
          <div class="chatRoomList_meetInfo" @click="goToChatRoom(cItems)">
            <div class="chatRoomList_block">
              <span class="colorRed chatRoomList_field">会议编号：</span>
              {{ cItems.meet_number }}
            </div>
            <div class="chatRoomList_block">
              <span class="colorRed chatRoomList_field">会议名称：</span>
              {{ cItems.title }}
            </div>
            <div class="chatRoomList_block">
              <span class="colorRed chatRoomList_field">会议时间：</span
              >{{ cItems.meet_start_time }}
            </div>
            <div class="chatRoomList_block">
              <span class="colorRed chatRoomList_field">会议链接：</span>
              <span
                @click.stop="openMeetingURL(cItems)"
                class="chatRoomtList_link"
                >点击打开参会来链接</span
              >
            </div>
            <div>
              <span class="colorRed chatRoomList_field">参会专家：</span>
              {{ cItems.doctos }}
            </div>
            <div
              class="chatRoomList_filed_point global_container_center"
              v-if="cItems.no_read > 0"
            >
              {{ cItems.no_read }}
            </div>
          </div>
        </template>
      </ListScroll>
    </div>
    <OutLogin class="out_login"></OutLogin>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
import ListScroll from "@/components/unit/ListScroll";
import OutLogin from "../meet/outLogin";
export default {
  name: "chatRoomList",
  components: {
    CustomButton,
    ListScroll,
    OutLogin,
  },
  data() {
    return {};
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = "骨转百讯|群聊列表";
    this.$store.dispatch("setItem", { signInfo: {} });
  },
  methods: {
    goToChatRoom(item) {
      this.$router.push({
        path: "/executorRoom",
        query: { id: item.id, role: 0 },
      });
    },
    openMeetingURL(item) {
      console.log(item);
      window.open(item.meet_url);
    },
  },
};
</script>
<style >
.index_container {
}
.chatRoomList {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1rem;
  height: 100%;
  padding-bottom: 0;
}
.chatRoomList .chatRoomList_main {
  flex: 1 0 0;
}
.chatRoomList .out_login {
}
.chatRoomList_meetInfo {
  background: #fff;
  padding: 0.5rem;
  line-height: 1.5;
  /* margin-bottom: 1rem; */
  border-bottom: 1px solid #ccc;
  position: relative;
  margin-bottom: 1rem;
}
.chatRoomList_filed_point {
  height: 1.2rem;
  width: 1.2rem;
  background: red;
  color: #fff;
  position: absolute;
  right: 0;
  top: calc(50% - 0.6rem);
  border-radius: 1.2rem;
  font-size: 0.8rem;
}

.chatRoomList_meetInfo:last-child {
  border-bottom: none;
}
.chatRoomList_block {
  display: flex;
}
.chatRoomList_field {
  white-space: nowrap;
}
.chatRoomtList_link {
  color: #2887f0;
  text-decoration: underline;
}
/* .qq {
  height: 100vh;
} */
</style>